import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class TaskTplApi extends BaseApi {
  async getTaskTplList(
    queryParameters: APIPaths["/app/task-tpl/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/task-tpl/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/task-tpl/list", { params: queryParameters })).data;
  }

  async getTaskTplById(
    pathParameters: APIPaths["/app/task-tpl/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/task-tpl/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/task-tpl/${pathParameters.id}`)).data;
  }

  async postUseTaskTpl(
    requestBody: APIPaths["/app/task-tpl/use"]["post"]["requestBody"]["content"]["application/json"],
  ) {
    return (await this.post("/app/task-tpl/use", requestBody)).data;
  }

  async postTaskTplNew(
    requestBody: APIPaths["/app/task-tpl/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task-tpl/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/task-tpl/new", requestBody)).data;
  }

  async patchTaskTplById(
    pathParameters: APIPaths["/app/task-tpl/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/app/task-tpl/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task-tpl/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/task-tpl/${pathParameters.id}`, requestBody)).data;
  }

  async deleteTaskTplById(
    pathParameters: APIPaths["/app/task-tpl/{id}"]["delete"]["parameters"]["path"],
  ): Promise<APIPaths["/app/task-tpl/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/task-tpl/${pathParameters.id}`)).data;
  }
}
