import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class PrimaryItemApi extends BaseApi {
  async getPrimaryItemList(
    parameters: APIPaths["/app/primary-item/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/primary-item/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/primary-item/list", {
        params: parameters,
      })
    ).data;
  }

  async getPrimaryItemIdNameList(
    parameters: APIPaths["/app/primary-item/id-name/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/primary-item/id-name/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/primary-item/id-name/list-all", {
        params: parameters,
      })
    ).data;
  }

  async deletePrimaryItemList(
    requestBody: APIPaths["/app/primary-item/list"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/list"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete("/app/primary-item/list", { data: requestBody })).data;
  }

  async putPrimaryItemListRestoration(
    requestBody: APIPaths["/app/primary-item/list/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/list/restoration"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put("/app/primary-item/list/restoration", requestBody)).data;
  }

  async postPrimaryItemNew(
    requestBody: APIPaths["/app/primary-item/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/primary-item/new", requestBody)).data;
  }

  async getPrimaryItemById(
    parameters: APIPaths["/app/primary-item/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/primary-item/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/primary-item/${parameters.id}`)).data;
  }

  async patchPrimaryItemById(
    id: string,
    requestBody: APIPaths["/app/primary-item/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/primary-item/${id}`, requestBody)).data;
  }

  async deletePrimaryItemById(
    id: string,
    requestBody?: APIPaths["/app/primary-item/{id}"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/primary-item/${id}`, { data: requestBody })).data;
  }

  async postPrimaryItemDuplicate(
    id: string,
    requestBody: APIPaths["/app/primary-item/{id}/duplicate"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/{id}/duplicate"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/app/primary-item/${id}/duplicate`, requestBody)).data;
  }

  async putPrimaryItemPosition(
    id: string,
    requestBody: APIPaths["/app/primary-item/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/primary-item/${id}/position`, requestBody)).data;
  }

  async putPrimaryItemRestorationById(
    id: string,
    requestBody?: APIPaths["/app/primary-item/{id}/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/{id}/restoration"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/primary-item/${id}/restoration`, requestBody)).data;
  }

  async putPrimaryItemMoveTab(
    id: string,
    requestBody: APIPaths["/app/primary-item/{id}/move-tab"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/{id}/move-tab"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/primary-item/${id}/move-tab`, requestBody)).data;
  }

  async putPrimaryItemListMoveTab(
    requestBody: APIPaths["/app/primary-item/move-tab/list"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/primary-item/move-tab/list"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/primary-item/move-tab/list`, requestBody)).data;
  }
}
