import { EmotionCache, Global } from "@emotion/react";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { ReactNode, useEffect, useState } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { _appStyled } from "@/styles/_app";
import { kintoSans } from "@/styles/app-typography";
import { muiTheme } from "@/styles/mui-theme";

import AuthProvider from "@/components/provider/AuthProvider";
import LocalStorageProvider from "@/components/provider/LocalStorageProvider";
import MuiLicenseProvider from "@/components/provider/MuiLicenseProvider";
import { GOOGLE_TAG_MANAGER_ID } from "@/constants/app-config";
import { GoogleTagManager } from "@next/third-parties/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider as JotaiProvider } from "jotai";
import { SessionProvider } from "next-auth/react";
import "react-toastify/dist/ReactToastify.css";

const Layout = dynamic(async () => import("@/components/Layout"), { ssr: false });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 10000,
      keepPreviousData: true,
    },
  },
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp({ Component, pageProps: { session, ...pageProps } }: MyAppProps): JSX.Element {
  const [devToolsNode, setDevToolsNode] = useState<ReactNode>(null);
  useEffect(() => {
    if (process.env.APP_ENV !== "local") {
      return;
    }
    setTimeout(async () => {
      const { ReactQueryDevtools } = await import("@tanstack/react-query-devtools");
      const node = <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />;
      setDevToolsNode(node);
    }, 1000);
  }, []);

  const render = () => {
    return (
      <main className={kintoSans.className}>
        <Layout noHeader={!!pageProps.noHeader}>
          <SessionProvider session={session}>
            <Component {...pageProps} />
          </SessionProvider>
        </Layout>
      </main>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      {devToolsNode}
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Global styles={_appStyled} />
        <JotaiProvider>
          <LocalStorageProvider>
            <AuthProvider>
              {/* 本番環境においてのみGoogle Tag Managerを表示 */}
              {process.env.APP_ENV === "production" && <GoogleTagManager gtmId={GOOGLE_TAG_MANAGER_ID} />}
              <MuiLicenseProvider>{render()}</MuiLicenseProvider>
            </AuthProvider>
          </LocalStorageProvider>
        </JotaiProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
