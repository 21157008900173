import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class TaskApi extends BaseApi {
  async getTaskList(
    parameters: APIPaths["/app/task/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/task/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/task/list", {
        params: parameters,
      })
    ).data;
  }

  async postTaskList(
    requestBody: APIPaths["/app/task/list"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/list"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/task/list", requestBody)).data;
  }

  async patchTaskList(
    requestBody: APIPaths["/app/task/list"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/list"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch("/app/task/list", requestBody)).data;
  }

  async putTaskListRestoration(
    requestBody: APIPaths["/app/task/list/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/list/restoration"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put("/app/task/list/restoration", requestBody)).data;
  }

  async getTaskMeList(
    parameters: APIPaths["/app/task/me/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/task/me/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/task/me/list", {
        params: parameters,
      })
    ).data;
  }

  async deleteTaskList(
    requestBody: APIPaths["/app/task/list"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/list"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete("/app/task/list", { data: requestBody })).data;
  }

  async postTaskNew(
    requestBody: APIPaths["/app/task/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/task/new", requestBody)).data;
  }

  async getTaskById(
    parameters: APIPaths["/app/task/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/task/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/task/${parameters.id}`)).data;
  }

  async patchTaskById(
    parameters: APIPaths["/app/task/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/app/task/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/task/${parameters.id}`, requestBody)).data;
  }

  async deleteTaskById(
    parameters: APIPaths["/app/task/{id}"]["delete"]["parameters"]["path"],
    requestBody?: APIPaths["/app/task/{id}"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/task/${parameters.id}`, { data: requestBody })).data;
  }

  async postTaskSyncGeneratedDescriptionById(
    parameters: APIPaths["/app/task/{id}/sync-generated-description"]["post"]["parameters"]["path"],
  ): Promise<APIPaths["/app/task/{id}/duplicate"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/app/task/${parameters.id}/sync-generated-description`)).data;
  }

  async postTaskDuplicateById(
    parameters: APIPaths["/app/task/{id}/duplicate"]["post"]["parameters"]["path"],
    requestBody: APIPaths["/app/task/{id}/duplicate"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/{id}/duplicate"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/app/task/${parameters.id}/duplicate`, requestBody)).data;
  }

  async putTaskStatusById(
    parameters: APIPaths["/app/task/{id}/status"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/task/{id}/status"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/{id}/status"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/task/${parameters.id}/status`, requestBody)).data;
  }

  async putTaskPeriodicById(
    parameters: APIPaths["/app/task/{id}/periodic"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/task/{id}/periodic"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/{id}/periodic"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/task/${parameters.id}/periodic`, requestBody)).data;
  }

  async putTaskListPeriodic(
    requestBody: APIPaths["/app/task/list/periodic"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/list/periodic"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put("/app/task/list/periodic", requestBody)).data;
  }

  async deleteTaskPeriodicById(
    parameters: APIPaths["/app/task/{id}/periodic"]["delete"]["parameters"]["path"],
    requestBody?: APIPaths["/app/task/{id}/periodic"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/{id}/periodic"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/task/${parameters.id}/periodic`, { data: requestBody })).data;
  }

  async putTaskPositionById(
    parameters: APIPaths["/app/task/{id}/position"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/task/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/task/${parameters.id}/position`, requestBody)).data;
  }

  async putTaskRestorationById(
    parameters: APIPaths["/app/task/{id}/restoration"]["put"]["parameters"]["path"],
    requestBody?: APIPaths["/app/task/{id}/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/{id}/restoration"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/task/${parameters.id}/restoration`, requestBody)).data;
  }

  async putTaskMoveTabList(
    id: string,
    requestBody: APIPaths["/app/task/{id}/move-tab"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/task/{id}/move-tab"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/task/${id}/move-tab`, requestBody)).data;
  }
}
